import { Component } from 'preact'
import cx from 'classnames'

import fetchProductPricing from 'lib/products/fetchPricing'

import { getStoredEmail, subscribeUser } from '../subscriptionForm/emailUtils'

import BookSpacerCover from './bookSpacerCover'
import { FullWidthText, FullWidthPreorderText } from './bookSpacerFullText'
import { HalfWidthText, HalfWidthPreorderText } from './bookSpacerHalfText'
import { MobileText, MobilePreorderText } from './bookSpacerMobileText'

import {
  isBookshelf,
  bookHref,
  trackGoal,
  isUpgradeMode,
  specialOfferStub,
  pickTitle,
} from './bookSpacerUtils'

class BookSpacer extends Component {
  constructor(props) {
    super(props)

    const { email, isSubscribed } = getStoredEmail(props.eventType)

    this.state = { prices: {}, email, isSubscribed }

    this.isHalf = props.size === 'half'
    this.isFull = props.size === 'full'
    this.isProductBookshelf = isBookshelf(props.id)
    this.href = bookHref(props.id)
    this.isPreorder = false

    this.trackClick = trackGoal.bind(this, props.goal)
    this.subscribe = this.subscribe.bind(this)
  }

  componentDidMount() {
    this.fetch()
  }

  get isCouponApplied() {
    return this.state.prices.isCouponApplied
  }

  get couponDiscount() {
    return this.state.prices.couponDiscount
  }

  get couponTheme() {
    return this.state.prices.couponTheme
  }

  fetch() {
    fetchProductPricing(this.props.id)
      .then((json) => {
        if (isUpgradeMode()) json.specialOffer = specialOfferStub

        this.setState({
          prices: json,
          hasSpecialOffer: json.specialOffer && json.specialOffer.oneTime && json.default,
        })
      })
      .catch(e => console.error(e)) // eslint-disable-line no-console
  }

  subscribe({ email }) {
    return subscribeUser({ email, eventType: this.props.eventType, eventId: '1' })
      .then(isSubscribed => this.setState({ isSubscribed }))
      .catch(e => console.error(e))// eslint-disable-line no-console
  }

  render() {
    if (!this.state.prices.default) return null

    const pickedTitle = pickTitle(this.props)

    const TextComponent = this.isFull ? FullWidthText : HalfWidthText
    const PreorderTextComponent = this.isFull ? FullWidthPreorderText : HalfWidthPreorderText

    const classes = cx(
      'bookSpacer',
      {
        is__full: this.isFull,
        is__half: this.isHalf,
        is__bookshelf: this.isProductBookshelf,
        is__book: !this.isProductBookshelf,
        is__upgradeAvailable: this.state.prices.specialOffer,
        is__preorder: this.isPreorder,
      },
      this.props.customClass
    )

    return (
      <div className={ classes }>
        <BookSpacerCover
          id={ this.props.id }
          cellSize={ this.props.size }
          href={ this.href }
          onClick={ this.trackClick }
          coverSize={ this.props.coverSize }
          fullCoverLaptopSize={ this.props.fullCoverLaptopSize }
          coverMobileSize={ this.props.coverMobileSize }
          halfCoverLaptopSize={ this.props.halfCoverLaptopSize }
          labelText={ this.props.labelText }
          isCouponApplied={ this.isCouponApplied }
          discount={ this.couponDiscount }
          couponTheme={ this.couponTheme }
        />

        { this.isPreorder &&
          <PreorderTextComponent
            { ...this.props }
            title={ pickedTitle }
            isSubscribed={ this.state.isSubscribed }
            href={ this.href }
            email={ this.state.email }
            onClick={ this.trackClick }
            onSubmit={ this.subscribe }
          />
        }

        { !this.isPreorder &&
          <TextComponent
            { ...this.props }
            productId={this.props.id}
            title={ pickedTitle }
            href={ this.href }
            prices={ this.state.prices }
            isProductBookshelf={ this.isProductBookshelf }
            hasSpecialOffer={ this.state.hasSpecialOffer }
            onClick={ this.trackClick }
          />
        }

        { this.isPreorder &&
          <MobilePreorderText
            { ...this.props }
            title={ pickedTitle }
            href={ this.href }
            onClick={ this.trackClick }
          />
        }

        { !this.isPreorder &&
          <MobileText
            { ...this.props }
            productId={this.props.id}
            title={ pickedTitle }
            titleMobile = { this.props.nameMobile }
            href={ this.href }
            prices={ this.state.prices }
            isProductBookshelf={ this.isProductBookshelf }
            hasSpecialOffer={ this.state.hasSpecialOffer }
            onClick={ this.trackClick }
          />
        }
      </div>
    )
  }
}

export default BookSpacer
