import { Component } from 'preact'
import { Cols } from '../cols'

import { birmanize } from 'lib/textHelper'
import { moscowifyLocal } from '../calendar/timezoneUtils'

import DiscountBannerSticker from './discountBannerSticker'
import DiscountBannerText from './discountBannerText'
import RealtimeCountdown from '../realtimeCountdown/realtimeCountdown'

import cx from 'classnames'

const MILLISECONDS_IN_HOUR = 60 * 60 * 1000
const LAST_HOURS_COUNT = 3

// FIXME: добавить нормальную интернационализаю и локализацию дат
class DiscountBanner extends Component {
  constructor(props) {
    super(props)

    this.openPaybar = this.openPaybar.bind(this)
  }

  componentDidMount() {
    this.$paybar = $('.js__newPaybar')
  }

  get isNoBleedBackground() {
    return this.props.bleedBackground === 'none'
  }

  get discountAmount() {
    return parseInt(this.props.discount, 10)
  }

  get hasExtraCharge() {
    return !Number.isNaN(this.discountAmount) && this.discountAmount < 0
  }

  get endsAtInitial() {
    return this.props.endsAt * 1000
  }

  get endsAtMoscowTime() {
    return moscowifyLocal(new Date(this.endsAtInitial))
  }

  get endsAtShortDate() {
    return this.props.endsAtShortDate || birmanize(this.endsAtMoscowTime)
  }

  get endsAtLongDate() {
    return this.props.endsAtLongDate || birmanize(this.endsAtMoscowTime, { format: 'fullMonthDateAndTime' })
  }

  get isDiscountTimeOver() {
    const dateNow = moscowifyLocal(new Date())
    const endsAt = this.endsAtMoscowTime

    return dateNow > endsAt
  }

  get isLastHoursLeft() {
    const dateNow = moscowifyLocal(new Date())
    const endsAt = this.endsAtMoscowTime
    const diffInHours = Math.abs(dateNow - endsAt) / MILLISECONDS_IN_HOUR

    return diffInHours <= LAST_HOURS_COUNT
  }

  get textSuffix() {
    return this.isDiscountTimeOver ? 'textTimeOver' : 'text'
  }

  get customDiscountTimeOverText() {
    return (this.isDiscountTimeOver && this.props.captionTextTimeOver) ? this.props.captionTextTimeOver : null
  }

  get customDiscountText() {
    return !this.isDiscountTimeOver && this.props.captionText
  }

  get customDesktopText() {
    const until = I18n.locale === 'en' ? 'until' : 'до'

    return this.customDiscountText ? `${this.props.captionText} ${until} ${this.endsAtLongDate}` : this.customDiscountTimeOverText
  }

  get customMobileText() {
    const until = I18n.locale === 'en' ? 'until' : 'до'

    return this.customDiscountText ? `${this.props.captionText} ${until} ${this.endsAtShortDate}` : this.customDiscountTimeOverText
  }

  get translationScope() {
    return this.hasExtraCharge ? 'discountBanner.withExtraCharge' : 'discountBanner'
  }

  get defaultDesktopText() {
    return I18n.t(`${this.translationScope}.${this.props.productType}.${this.textSuffix}`, { endsAt: this.endsAtLongDate })
  }

  get defaultMobileText() {
    return I18n.t(`${this.translationScope}.${this.props.productType}.${this.textSuffix}`, { endsAt: this.endsAtShortDate })
  }

  get discountSpacerDesktopText() {
    return this.customDesktopText || this.defaultDesktopText
  }

  get discountSpacerMobileText() {
    return this.customMobileText || this.defaultMobileText
  }

  get hasCustomText() {
    return !!this.customDesktopText
  }

  get hasBlackTheme() {
    return !!this.props.hasBlackTheme
  }

  openPaybar(e) {
    if (e.target && e.target.nodeName !== 'A') {
      // HACK: stickyButton is rendered inside NewPaybar
      // because of this it cannot be found in DOM when DiscountBanner did mount
      this.$paybar.find('.stickyButton').click()
    }
  }

  render() {
    const classes = cx(
      'module discountSpacerWrap',
      `is__${this.props.productType}Discount`,
      {
        is__discountTimeOver: this.isDiscountTimeOver,
        is__lastHoursLeft: this.isLastHoursLeft,
        is__bleedBackgroundSides: !this.isNoBleedBackground,
        has__blackTheme: this.hasBlackTheme,
        [`is__${this.props.theme}Theme`]: this.props.theme,
      }
    )

    return (
      <div className={ classes } onClick={ this.openPaybar }>
        <Cols
          className="discountSpacer"
          divisionProportions="7:9"
          transposeOnMobile
        >
          <div className="discountSpacer-cols">
            <DiscountBannerSticker
              discount={ this.props.discount }
              icon={ this.props.stickerIcon }
              caption={ this.props.stickerText }
              hasBlackTheme={ this.hasBlackTheme } />

            <DiscountBannerText
              productType={ this.props.productType }
              desktopText={ this.discountSpacerDesktopText }
              mobileText={ this.discountSpacerMobileText }
              hasCustomText={ this.hasCustomText } />
          </div>

          <div className="discountSpacer-timer">
            { !this.hasBlackTheme &&
              <RealtimeCountdown to={ this.endsAtInitial } hasSeconds hasShortUnits />
            }
          </div>
        </Cols>
      </div>
    )
  }
}

export default DiscountBanner
