import DiscountBanner from './discountBanner'

const productSubscription = window.productSubscription || {}
const existingSubscription = productSubscription.subscription || {}
const productPrices = productSubscription.prices || {}

const productType = existingSubscription.product || ''
const isCouponApplied = productPrices.isCouponApplied
const discount = productPrices.couponDiscount
const endsAt = productPrices.couponValidUntil
const theme = productPrices.couponTheme

$('.js__discountBanner').each(function() {
  const options = $(this).data('options')
  const props = {
    productType,
    discount,
    endsAt,
    theme,
    ...options,
  }

  if (!isCouponApplied && !options.isVisible) return $(this).remove()

  return $(this).reactify(DiscountBanner, props)
})
