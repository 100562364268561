import { Component } from 'preact'
import { Caption } from '../textNodes'

import cx from 'classnames'

class BookSpacerCouponSticker extends Component {
  get hasPercentageDiscount() {
    const discountAmount = parseInt(this.props.discount, 10)

    return !isNaN(discountAmount) && discountAmount > 0
  }

  get discountIcon() {
    const textWithDiscountAmount = `${ this.props.discount }<span>%</span>`

    return this.hasPercentageDiscount ? textWithDiscountAmount : '%'
  }

  get hasBlackTheme() {
    return !!this.props.hasBlackTheme
  }

  render() {
    const wrapperClasses = cx(
      'bookSpacerCouponSticker-wrapper',
      {
        has__blackTheme: this.hasBlackTheme,
        [`is__${this.props.theme}Theme`]: this.props.theme,
      }
    )

    const stickerClasses = cx(
      'bookSpacerCouponSticker',
      { has__percentageDiscount: this.hasPercentageDiscount }
    )

    return (
      <div className={ wrapperClasses }>
        <div className={ stickerClasses }>
          <div
            className="percentage"
            dangerouslySetInnerHTML={{ __html: this.discountIcon }} />

          { this.hasPercentageDiscount &&
            <Caption html={ I18n.t('books.bookSpacer.discount') } />
          }
        </div>
      </div>
    )
  }
}

export default BookSpacerCouponSticker
